import React from "react";
import TestContainer from "../TestContainer/TestContainer";
import './ChallengeSection.css'

const ChallengeSection = ({selectedParagraph, words, characters, wpm, timeRemaining, timerStarted, testInfo, onInputChange, startAgain}) => {
    
    
    return (
        <div className="challengeSection-container">
            <h1 className="challengeSection-header" data-aos="fade-down">
            Take a speed test now
            </h1>
            <TestContainer 
            timeRemaining={timeRemaining} 
            selectedParagraph={selectedParagraph} 
            timerStarted={timerStarted} 
            words={words} 
            characters={characters} 
            wpm={wpm} 
            testInfo={testInfo} 
            onInputChange={onInputChange} 
            startAgain = {startAgain}
            />

        </div>
    )
}

export default ChallengeSection