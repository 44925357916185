import React from "react";
import ChallengeDetailsCard from "../ChallengeDetailsCard/ChallengeDetailsCard";
import TypingChallenge from "../TypingChallenge/TypingChallenge";
import "./TypingChallengeContainer.css"

const TypingChallengeContainer = ({ words, characters, wpm, selectedParagraph, timeRemaining, timerStarted, testInfo, onInputChange }) => {
    return (
        <div className="typing-challenge-container">
            {/* Details Section */}
            <div className="details-container">
                {/*Words typed */}
                <ChallengeDetailsCard cardName="words" cardValue={words} />

                {/*Characters typed */}
                <ChallengeDetailsCard cardName="characters" cardValue={characters} />


                {/*Speed  */}
                <ChallengeDetailsCard cardName="wpm" cardValue={wpm} />


            </div>

            {/* The REAL Challenge */}
            <div className="typewriter-container">
                <TypingChallenge 
                timerStarted={timerStarted} 
                timeRemaining={timeRemaining} 
                selectedParagraph={selectedParagraph} 
                testInfo={testInfo}
                onInputChange = {onInputChange}
                />
            </div>
        </div>
    )
}

export default TypingChallengeContainer