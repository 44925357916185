import React from "react";
import './Footer.css'

const Footer = () => {
    return (
        <div className="footer-container">
            <a href="https://jmariwyatt.com" 
            className="footer-link"
            target="_blank"
            rel="noreferror">
            My Portfolio</a>
        </div>
    )
}

export default Footer