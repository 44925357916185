import React from "react";
import logo from "../../Assets/logo.png"
import "./Nav.css"

const Nav = () => {
    return (
        <div className="nav-container">
            <div className="nav-left">
                <img className="flash-logo" src={logo} alt="logo"/>
                <p className="flash-logo-text">TypeTest</p>
            </div>
            <div className="nav-right">
                <a 
                href="https://jmariwyatt.com" 
                className="nav-page-link" 
                target={"_blank"} 
                rel="noreferrer">My Portfolio</a>
            </div>
    </div>
        )
    
}

export default Nav